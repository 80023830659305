<template>
  <div class="box">
    <el-card class="box-card" shadow="never">
      <div class="clearfix" slot="header">
        <span>
          <i class="el-icon-edit"></i>
          {{type}}菜单
        </span>
      </div>
      <el-form :model="form" :rules="rules" label-width="80px" ref="form" size="medium">
        <el-form-item label="上级菜单" prop="parent_id">
          <el-cascader
            :options="menuTree"
            :props="{ checkStrictly: true, label: 'name', value: 'menu_id' }"
            @change="changeCascader"
            clearable
            placeholder="不选择表示顶级菜单"
            style="width: 100%"
            v-model="form.parent_id"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="名称" prop="name" required>
          <el-input placeholder="请输入菜单名称" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="路由" prop="route" required>
          <el-input placeholder="请输入链接地址" v-model="form.route"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="排序" prop="list_order">
              <el-input-number
                :max="100"
                :min="1"
                controls-position="right"
                v-model="form.list_order"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="菜单" prop="type">
              <el-switch
                :active-value="1"
                :inactive-value="2"
                active-color="#409eff"
                inactive-color="#DCDFE6"
                v-model="form.type"
              ></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="图标">
          <el-input placeholder="请输入" v-model="form.icon"></el-input>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card" shadow="never" style="margin-top: 10px">
      <div class="clearfix" slot="header">
        <span>
          <i class="el-icon-edit"></i> 关联接口
        </span>
      </div>
      <div class="api-list">
        <el-checkbox-group v-model="form.api_list">
          <el-checkbox
            :key="index"
            :label="item.value"
            v-for="(item, index) in apiData"
          >{{item.label}} / {{item.value}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  props: {
    form: {},
    type: {
      default: '新增',
    },
    menuTree: {},
  },
  data() {
    return {
      // form: {
      //   parent_id: 0,
      //   name: '',
      //   route: '',
      //   list_order: 1,
      //   type: 1,
      // },
      rules: {
        name: [{ required: true, message: '必需填写', trigger: 'blur' }],
        route: [{ required: true, message: '必需填写', trigger: 'blur' }],
      },

      apiData: [],
      api_list: [],
    }
  },
  mounted() {
    this.getIndexApi()
  },
  methods: {
    getIndexApi() {
      //获取所有接口
      this.$axios.post('/index/api').then((res) => {
        if (res.data.code == 200) {
          for (let key in res.data.data) {
            this.apiData.push({
              value: key,
              label: res.data.data[key],
            })
          }
        }
      })
    },
    changeCascader(val) {
      this.form.parent_id = val[val.length - 1]
    },
  },
}
</script>
<style lang="less" scoped>
.box {
  margin-bottom: 10px;
}

.box-card {
  /deep/ .el-card__header {
    padding: 11px 11px;
  }
  /deep/ .el-card__body {
    padding: 20px 11px;
  }
  /deep/ .el-form-item {
    margin-bottom: 15px;
  }
}
.api-list {
  /deep/ .el-checkbox {
    display: inline-block;
    width: calc(50% - 30px);
  }
}
</style>