<template>
  <el-card class="main-content" data-title="菜单配置" shadow="hover" v-title>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-tree :data="menuTree" :props="defaultProps" default-expand-all :expand-on-click-node="false">
          <div class="tree-node" slot-scope="{ node, data }">
            <span class="name">{{ node.label }}</span>
            <span class="button">
              <el-button @click="addMenu(data)" size="mini" type="text">新增</el-button>
              <el-button @click="editMenu(data)" size="mini" type="text">编辑</el-button>
              <el-button @click="removeMenu(data)" size="mini" type="text">删除</el-button>
            </span>
          </div>
        </el-tree>
      </el-col>
      <el-col :offset="2" :span="12">
        <edit-menu :form="menuForm" :menuTree="menuTree" :type="type" ref="editMenu"></edit-menu>
        <div class="form-footer">
          <el-button @click="submitForm()" type="primary">立即提交</el-button>
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
import EditMenu from '@/components/config/EditMenu.vue'
export default {
  components: {
    EditMenu,
  },
  data() {
    return {
      menuTree: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      menuForm: {
        parent_id: 0,
        name: '',
        route: '',
        list_order: 1,
        type: 1,
        api_list: [],
        icon: ''
      },
      type: '新增', //新增  编辑
    }
  },
  mounted() {
    this.getMenuTree()
  },
  methods: {
    getMenuTree() {
      this.$axios.post('/menu/lists').then((res) => {
        if (res.data.code == 200) {
          this.menuTree = res.data.data
        }
      })
    },
    addMenu(data) {
      this.type = '新增'
      this.menuForm = {
        parent_id: data.menu_id,
        name: '',
        route: '',
        list_order: 1,
        type: 1,
        api_list: [],
        icon: ''
      }
    },
    editMenu(data) {
      this.type = '编辑'
      this.menuForm = data
    },
    removeMenu(data) {
      this.$confirm('确认删除「' + data.name + '」菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios.post('/menu/remove', {
            menu_id: data.menu_id
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.getMenuTree()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    submitForm() {
      if (this.menuForm.name == '') {
        this.$message.error('名称不能为空')
        return false
      }
      if (this.menuForm.route == '') {
        this.$message.error('路由不能为空')
        return false
      }

      let url = this.type == '新增' ? '/menu/create' : '/menu/edit'

      this.$axios.post(url, this.menuForm).then((res) => {
        if (res.data.code == 200) {
          this.$message.success(res.data.msg)
          this.getMenuTree()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.tree-node {
  display: flex;
  width: 100%;
  .name {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.form-footer {
  padding: 0 20px;
  text-align: right;
}
</style>